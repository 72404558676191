import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { APIHelper } from '../../services/APIHelper';
import { DataValue } from '../../types/models/DataValue';
import { TranslocoService } from '@ngneat/transloco';
@Component({
  selector: 'app-registerpage',
  template: `
<div class="register-container">
<div class="backhomepage" routerLink="/">
    <div>
      <img src="../../assets/images/left_arrow.png" style="width: 25px"/>
    </div>  
    <div>
    Back to Homepage
  </div>
  </div>
<div class="card"  data-aos="zoom-out-left" data-aos-duration="500">
  <div style="text-align: center;cursor:pointer" routerLink="/">
    <img src="../../assets/images/logo.png" style="width: 200px"/>
  </div>
  <div>
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="register()">
           <div style="display: flex;column-gap: 10px">
            
            <div class="mb-3" style="width:100%">
                <label class="form-label">First Name</label>
                <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="mb-3" style="width:100%">
                <label class="form-label">Last Name</label>
                <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
            </div>
          </div>

            
            <!-- <div class="mb-3">
                <label class="form-label">Email</label>
                <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                </div>
            </div> -->



            <div class="mb-3">
              

                <label class="form-label">Phone Number</label>

                <div style="display: flex;column-gap: 10px">
                  <p-dropdown
                      [options]="countries"
                      style="display: block"
                      formControlName="countryCode"
                      optionLabel="Text"
                      optionValue="Value"
                  ></p-dropdown>
              
                  <input type="number" formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
                 

                </div>
                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                      <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">Password Confirmation</label>
                <input type="password" formControlName="passwordConfirmation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.passwordConfirmation.errors }" />
                <div *ngIf="submitted && f.passwordConfirmation.errors" class="invalid-feedback">
                    <div *ngIf="f.passwordConfirmation.errors.required">Password Confirmation is required</div>
                </div>
               
            </div>
            <div *ngIf="submitted && isPasswordNotMatching" class="invalid-register">
                    <div *ngIf="isPasswordNotMatching">Password Not Matching</div>
            </div>
            <div *ngIf="errorMessage" class="invalid-register">
                    <div >{{errorMessage}}</div>
            </div>
            <div id="recaptcha-container"></div>


            <div style="display: flex;align-items: center;flex-direction: column;">
                <button [disabled]="loading" class="primary-btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Register
                </button>
                <label style="margin-top: 20px;color:gray">Already have an Account?</label>
                <a routerLink="/login" class="btn btn-link">Login</a>
            </div>
        </form>
        <p-dialog [draggable]="false" header="" [(visible)]="displayPhoneVerification">
            <div style="
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
">
            <img src="../../assets/images/phoneverification.gif" style="width: 200px"/>
              <label style="color: black;font-weight: bold;font-size: 20px;">Enter the OTP Code from Phone Number</label>
              <label>The 6-digit verification sent to {{phoneNumberText}}</label>
              <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
              <button class="primary-btn margin-10 padding-10-20" (click)="VerifyPhoneNumber()" style="margin-top: 50px;" type="submit">
              <span *ngIf="loadingVerification" class="spinner-border spinner-border-sm me-1"></span>
               Verify
             </button>
          </div>    
        
      </p-dialog>

      <p-dialog [draggable]="false" header="" (onHide)="onRegSuccessHide()" [(visible)]="isRegisteredSuccess">
            <div style="
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
">
            <img src="../../assets/images/success.png" style="width: 200px"/>
              <label style="color: black;font-weight: bold;font-size: 20px;">Registered Successfully</label>
              <label>Thank you for joining our app. You are now able to login, check our latest products and buy what you need!</label>
              <button class="primary-btn margin-10 padding-10-20" (click)="GoToLoginPage()" style="margin-top: 50px;" type="submit">
               Login
             </button>
          </div>    
        
      </p-dialog>
    </div>
</div></div>
  `,
  styleUrls: ['./registerpage.component.scss']
})
export class RegisterpageComponent implements OnInit {
  loading = false;
  loadingVerification = false;
  submitted = false;
  form: FormGroup;
  countries: DataValue[]
  selectedCountryCode: DataValue | undefined
  otp: string = ""; 
  OtpVerification: string = "";
  phoneNumberText: string = "";
  showOtpComponent = true;
  isPasswordNotMatching = false;
  displayPhoneVerification = false;
  isRegisteredSuccess = false;
  errorMessage: string = "";
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any; config = { allowNumbersOnly: true, length: 4, isPasswordInput: false, disableAutoFocus: false, placeholder: "*", inputStyles: { width: "50px", height: "50px", }, }; 
  
  phoneNumberConfirmationResult: string = "";
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private httpService: APIHelper,
    private translocoService: TranslocoService,
    private fb: FormBuilder) {
      this.countries = [
        { Text: "+961", Value: "961"},
        // { Text: "+48", Value: "48"},
        // { Text: "+818", Value: "818"},
        // { Text: "+368", Value: "368"},
        // { Text: "+400", Value: "400"},
        // { Text: "+414", Value: "414"},
      ]

      this.form = this.fb.group({
        email: [''],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        password: ['', Validators.required],
        passwordConfirmation: ['', Validators.required],
        countryCode: [this.countries[0], Validators.required],
        phoneNumber: ['', Validators.required],
      });

    
     }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

  register() {
    this.submitted = true
    this.errorMessage = "";
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    if(this.form.value.password != this.form.value.passwordConfirmation){
      this.isPasswordNotMatching = true;
      this.loading = false;
      return;
    }
    this.phoneNumberText = this.form.value.countryCode.Text.split("+")[1] + this.form.value.phoneNumber;

    this.httpService.CheckPhoneNumberExist(this.phoneNumberText)
      .subscribe(phoneNumberExistResult => {
        if(phoneNumberExistResult != true){
          this.httpService.SendVerificationCode(this.phoneNumberText)
          .subscribe((result: any) => {
            console.log(result);
            if(result){
              this.submitted = false;
              this.loading = false;
              this.displayPhoneVerification = true;
              this.phoneNumberConfirmationResult = result;
            } else {
              this.submitted = false;
            }
          })
        } else {
          this.errorMessage = this.translocoService.translate('PhoneNumberExist');
          this.submitted = false;
          this.loading = false;
          this.displayPhoneVerification = false;
        }
      
      })


  }

  onOtpChange(event: any) {
    this.OtpVerification = event;
  }

  VerifyPhoneNumber() {
    this.phoneNumberText = this.form.value.countryCode.Text + this.form.value.phoneNumber;
    const phoneNumberValue = this.form.value.countryCode.Value + this.form.value.phoneNumber;
    const name = this.form.value.firstName + " " + this.form.value.lastName;
    this.loadingVerification = true;
    if(this.phoneNumberConfirmationResult && this.phoneNumberConfirmationResult.toString() == this.OtpVerification.toString()){
      const email = phoneNumberValue + "@hebacosmatic.com"
      this.httpService.RegisterUser(
          phoneNumberValue, this.form.value.password, email, name)
            .subscribe((result: any) => {
              if(result.Result == "success"){
                  this.loading = false;
                  this.displayPhoneVerification = false;
                  this.isRegisteredSuccess = true;

                  this.httpService.Login(phoneNumberValue, this.form.value.password)
                  .subscribe((result1: any) => {
                    if(result1){
                      this.loading = false;
                      if(result1.Result == "Success"){
                        const data = result1.Data;
                        localStorage.setItem("UserInfo", JSON.stringify(data));
                        this.httpService.GetCustomerInfo();
                        this.router.navigateByUrl('/home');
                      }
                      else if(result1.message == "account.login.wrongcredentials.customernotexist"){
                        this.errorMessage = "Invalid Credentials" 
                      }
                      else {
                        this.errorMessage = result1.message
                      }
                    }
                  })

              } else {
                this.errorMessage = result.message;
                this.displayPhoneVerification = false;
                this.isRegisteredSuccess = false;
                this.loading = false;
              }
            })
     
    } else {
      console.error('Verification code is incorrect');
    }
  
  }

  GoToLoginPage(){
    this.router.navigateByUrl("/login");
  }

  onRegSuccessHide(){
    this.router.navigateByUrl("/");

  }

  emailSignup(email: string, password: string) {
    this.afAuth.createUserWithEmailAndPassword(email, password)
    .then(value => {
     this.router.navigateByUrl('/profile');
    })
    .catch(error => {
      console.log('Something went wrong: ', error);
    });
  }

}
