import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  template: `<router-outlet (activate)="onActivate($event)"></router-outlet>`
})
export class AppComponent implements OnInit{
  title = 'frontend';
  constructor(private fire: AngularFirestore){}
  ngOnInit(): void {
     AOS.init({
      disable: false,
      startEvent: 'DOMContentLoaded',
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate', 
      disableMutationObserver: false,
      debounceDelay: 250,
      throttleDelay: 99,
      useClassNames: false,
      offset: 120,
      duration: 400,
      easing: 'ease',
      once: false,
      mirror: false, 
      anchorPlacement: 'top-bottom'
     });
  //   firebase.database().ref('.info/connected').on('value', function(snapshot) {
  //     // If we're not currently connected, don't do anything.
  //     if (snapshot.val() == false) {
  //         return;
  //     };
  //     console.log(snapshot)
  // });
  }

  onActivate(event: any) {
    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     });
 }
}
