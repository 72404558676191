import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataValue } from '../../types/models/DataValue';
import { APIHelper } from '../../services/APIHelper';
import { StoreService } from '../../services/store';
@Component({
  selector: 'app-loginpage',
  template: `
<!-- <div style="background-color: rgb(228, 228, 228);">
  <div style="margin-top: 4rem " >
    <div class="row card form-control">
      <h5>Sign in to continue.</h5>
      <form class="pt-3" [formGroup]="form" (ngSubmit)="login()">
        <div class="form-group mt-3">
          <input style="background-color: rgb(252, 252, 252);"
            type="email"
            class="form-control"
            required
            formControlName="phoneNumber"
            id="exampleInputEmail1"
            placeholder="phoneNumber"
          />
        </div>
        <div class="form-group mt-3">
          <input style="background-color: rgb(252, 252, 252);"
            type="password"
            class="form-control"
            formControlName="password"
            id="exampleInputPassword1"
            placeholder="Password"
          />
        </div>
        <div class="mt-3">
          <button class="btn custom" type="submit">
            Sign In
          </button>
        </div>
        <div class="my-2 d-flex justify-content-between align-items-center">
          <div></div>
        </div>
      </form>
    </div>
  </div>
</div> -->
<div class="login-container">
  <div class="backhomepage"  routerLink="/">
    <div>
      <img src="../../assets/images/left_arrow.png" style="width: 25px"/>
    </div>  
    <div>
    Back to Homepage
  </div>
  </div>
<div class="card"  data-aos="zoom-out-right" data-aos-duration="500">
  <div style="text-align: center;cursor:pointer" routerLink="/">
    <img src="../../assets/images/logo.png" style="width: 200px"/>
  </div>
  <div>
    <!-- <h4 class="card-header">Login</h4> -->
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="login()">
            <div class="mb-3">
                <label class="form-label">Phone Number</label>
                <div style="display: flex;column-gap: 10px">
                  <p-dropdown
                      [options]="countries"
                      style="display: block"
                      formControlName="countryCode"
                      optionLabel="Text"
                      optionValue="Value"
                  ></p-dropdown>
              
                  <input type="number" formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
                 

                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              
            </div>
            <div style="display: flex;align-items: center;flex-direction: column;">
                <div *ngIf="submitted && errorMessage" class="invalid-login">
                    <div >{{errorMessage}}</div>
                </div>

                <button [disabled]="loading" class="primary-btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Login
                </button>
                <a routerLink="/forgotpassword" style="margin-top: 10px;text-decoration: underline" class="btn btn-link">Forgot Password</a>


                <label style="margin-top: 20px;color:gray">Don't have an Account?</label>
                <a routerLink="/register" class="btn btn-link">Register</a>
            </div>
        </form>
    </div>
</div></div>
  `,
  styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent implements OnInit {
  loading = false;
  submitted = false;
  form: FormGroup;
  countries: DataValue[]
  errorMessage: string = "";
  selectedCountryCode: DataValue | undefined

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private httpService: APIHelper,
    private store: StoreService,
    private fb: FormBuilder) {
      this.countries = [
        { Text: "+961", Value: "961"},
        // { Text: "+48", Value: "48"},
        // { Text: "+818", Value: "818"},
        // { Text: "+368", Value: "368"},
        // { Text: "+400", Value: "400"},
        // { Text: "+414", Value: "414"},
      ]

      this.form = this.fb.group({
        phoneNumber: ['', Validators.required],
        password: ['', Validators.required],
        countryCode: [this.countries[0]]
      });
     }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

 login() {
  this.submitted = true;
  if (this.form.invalid) {
    return;
  }
  this.errorMessage = "";
  this.loading = true;

  this.httpService.Login(this.form.value.countryCode.Value + this.form.value.phoneNumber, this.form.value.password)
                  .subscribe((result: any) => {
                    if(result){
                      this.loading = false;
                      if(result.Result == "Success"){
                        const data = result.Data;
                        localStorage.setItem("UserInfo", JSON.stringify(data));
                        this.httpService.GetCustomerInfo();
                        this.router.navigateByUrl('/home');
                      }
                      else if(result.message == "account.login.wrongcredentials.customernotexist"){
                        this.errorMessage = "Invalid Credentials" 
                      }
                      else {
                        this.errorMessage = result.message
                      }
                    }
                  })
  }

}
